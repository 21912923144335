




























































































































































































































































































































































import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root, emit }) {
    const model = reactive<{
      data: any;
    }>({
      data: {
        name: "",
        type: "",
        prefix: "",
        startDate: "",
        endDate: "",
        freeRegistration: "",
        endRegistrationDate: "",
        amount: "",
        currency: "PLN",
        languageVersion: "",
        modules: {
          invoiceModuleEnabled: "",
          companyModuleEnabled: false,
          abstractModuleEnabled: false,
          budgetModuleEnabled: false,
          posterSessionModuleEnabled: false,
          surveyModuleEnabled: false,
          printModuleEnabled: false,
          additionalServiceModuleEnabled: false,
          accommodationModuleEnabled: false,
        }
      },
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      items: [],
      dialog: false,
      startDateClose: false,
      endDateClose: false,
      endRegistrationDateClose: false,
      minDate: computed(() => model.data.startDate),
      endDate: computed(() => model.data.endDate),
      amount: false,
    });

    const isAllChecked = computed(
      () => Object.values(model.data.modules).filter((m) => !m).length <= 0
    );
    const isAllIndeterminate = computed(
      () =>
        Object.values(model.data.modules).filter((m) => !m).length <
        Object.values(model.data.modules).length
    );

    const onAll = () => {
      const isAnyUnchecked = Object.values(model.data.modules).filter((m) => !m).length > 0;
      if (isAnyUnchecked) {
        model.data.modules.invoiceModuleEnabled = true;
        model.data.modules.companyModuleEnabled = true;
        model.data.modules.abstractModuleEnabled = true;
        model.data.modules.budgetModuleEnabled = true;
        model.data.modules.posterSessionModuleEnabled = true;
        model.data.modules.surveyModuleEnabled = true;
        model.data.modules.printModuleEnabled = true;
        model.data.modules.additionalServiceModuleEnabled = true;
        model.data.modules.accommodationModuleEnabled = true;
      } else {
        model.data.modules.invoiceModuleEnabled = false;
        model.data.modules.companyModuleEnabled = false;
        model.data.modules.abstractModuleEnabled = false;
        model.data.modules.budgetModuleEnabled = false;
        model.data.modules.posterSessionModuleEnabled = false;
        model.data.modules.surveyModuleEnabled = false;
        model.data.modules.printModuleEnabled = false;
        model.data.modules.additionalServiceModuleEnabled = false;
        model.data.modules.accommodationModuleEnabled = false;
      }
    };

    //  Start fetch single event data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.data.name = event.name;
          model.data.type = event.type;
          model.data.siemensPrefix = event.siemensPrefix;
          model.data.prefix = event.prefix;
          model.data.amount = event.amount || undefined;
          model.data.currency = event.currency;
          model.data.languageVersion = event.languageVersion || "";
          model.data.startDate = event.startDate || "";
          model.data.endDate = event.endDate || "";
          model.data.startTime = event.startTime || "";
          model.data.endTime = event.endTime || "";
          model.data.endRegistrationDate = event.endRegistrationDate;
          model.data.freeRegistration = event.freeRegistration;
          model.data.modules.invoiceModuleEnabled = event.invoiceModuleEnabled || "";
          model.data.modules.companyModuleEnabled = event.companyModuleEnabled || false;
          model.data.modules.abstractModuleEnabled =
            event.abstractModuleEnabled || false;
          model.data.modules.budgetModuleEnabled = event.budgetModuleEnabled || false;
          model.data.modules.posterSessionModuleEnabled =
            event.posterSessionModuleEnabled || false;
          model.data.modules.surveyModuleEnabled = event.surveyModuleEnabled || false;
          model.data.modules.printModuleEnabled = event.printModuleEnabled || false;
          model.data.modules.additionalServiceModuleEnabled =
            event.additionalServiceModuleEnabled || false;
          model.data.modules.accommodationModuleEnabled =
            event.accommodationModuleEnabled || false;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single event data

    watch(
      () => model.data.modules,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules = {
      name: [(v: string) => !!v || root.$t("rules.enterName")],
      prefix: [(v: string) => !!v || root.$t("rules.enterPrefix")],
      type: [(v: string) => !!v || root.$t("selectEventType")],
      language: [(v: string) => !!v || root.$t("chooseLanguageVersion")],
      minEndDate: [
        (v: string) =>
          !state.minDate ||
          v >= state.minDate ||
          root.$t("rules.selectCorrectDate"),
      ],
    };

    watch(
      () => state.amount,
      () => {
        if (!state.amount) {
          model.data.amount = null;
        }
      }
    );

    watch(
      () => model.data.amount,
      () => {
        model.data.amount ? (state.amount = true) : (state.amount = false);
      }
    );

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        type: model.data.type,
        amount: model.data.amount || null,
        siemensPrefix: model.data.siemensPrefix || null,
        languageVersion: model.data.languageVersion,
        startDate: model.data.startDate,
        endDate: model.data.endDate,
        startTime: model.data.startTime,
        endTime: model.data.endTime,
        freeRegistration: model.data.freeRegistration,
        endRegistrationDate: model.data.endRegistrationDate,
        currency: model.data.currency,
        invoiceModuleEnabled: model.data.modules.invoiceModuleEnabled || false,
        companyModuleEnabled: model.data.modules.companyModuleEnabled || false,
        abstractModuleEnabled: model.data.modules.abstractModuleEnabled || false,
        budgetModuleEnabled: model.data.modules.budgetModuleEnabled || false,
        posterSessionModuleEnabled:
          model.data.modules.posterSessionModuleEnabled || false,
        surveyModuleEnabled: model.data.modules.surveyModuleEnabled || false,
        printModuleEnabled: model.data.modules.printModuleEnabled || false,
        additionalServiceModuleEnabled:
          model.data.modules.additionalServiceModuleEnabled || false,
        accommodationModuleEnabled:
          model.data.modules.accommodationModuleEnabled || false,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.eventExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const {
      languageItems,
      eventTypeItemsLite,
      currencyItems,
    } = useSelectItems({ root });

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      rules,
      eventTypeItemsLite,
      languageItems,
      isAllChecked,
      isAllIndeterminate,
      onAll,
      currencyItems,
    };
  },
});
